import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import circleImg from 'assets/circulo_home.png'
import StepCard from './components/StepCard'
import DistribuirPedidoPage from './RecepcionPedidos/DistribuirPedido/DistribuirPedidoPage'
import CantidadesRecibidasPage from './RecepcionPedidos/CantidadRecibidas/CantidadesRecibidasPage'
import GenerarRemitosPage from './RecepcionPedidos/GenerarYVerRemito/GenerarRemitosPage'
import { useAuth } from 'contexts/AuthContext'
import { usePedidos } from 'contexts/PedidosContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModalProvider } from 'contexts/ModalContext'
import RotatingLoading from 'components/Loading/Loading'

const RecepcionPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ImageOverlay = styled.img`
  position: absolute;
  width: 100%;
  height: 35vh;
  filter: brightness(93%);
  z-index: -999999;
`
const StepsWrapper = styled.div`
  z-index: 2;
  margin-top: 6vh;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

const TableWrapper = styled.div`
  margin: 20px 10%;
  border-radius: 20px 20px 0 0;
  background-color: ${({ theme }) => theme.colors.neutral.almostWhite};
`

const RecepcionPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location

  const {
    getPedidoAgrupacion,
    setPedidoAgrupacion,
    recepcionPedido,
    pedidoLoading,
    pedidosLoading,
  } = usePedidos()
  const [activeStep, setActiveStep] = useState(recepcionPedido.paso || 1)

  const getOcNumber = () => {
    const routeParts = location.pathname.split('/')
    return routeParts[routeParts.length - 1]
  }
  const oc_number = getOcNumber(location)
  const { user, token } = useAuth()

  useEffect(() => {
    const fetchPedidosAgrupacion = async () => {
      try {
        if (user && oc_number) {
          const step = await getPedidoAgrupacion(oc_number, state.ordenCompra)
          setActiveStep(step)
        }
      } catch (error) {
        setPedidoAgrupacion({})
        console.error('Error al obtener el pedido de agrupación: ', error)
      }
    }
    if (user.rol && activeStep === 1) {
      fetchPedidosAgrupacion()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])

  const goToNextStep = () => {
    if (activeStep < 4) setActiveStep(activeStep + 1)
  }

  const steps = [
    {
      title: 'Cantidades recibidas',
    },
    {
      title: 'Distribuir pedido',
    },
    {
      title: 'Generar Remitos',
    },
    {
      title: 'Ver Remitos',
    },
  ]

  let stepContent
  switch (activeStep) {
    case 1:
      stepContent = (
        <ModalProvider>
          <CantidadesRecibidasPage
            goToNextStep={() =>
              user.rol.id_rol === '3' ? navigate('/pedidos') : goToNextStep()
            }
          />
        </ModalProvider>
      )
      break
    case 2:
      stepContent = (
        <ModalProvider>
          <DistribuirPedidoPage goToNextStep={goToNextStep} />
        </ModalProvider>
      )
      break
    case 3:
      stepContent = (
        <ModalProvider>
          <GenerarRemitosPage
            goToNextStep={goToNextStep}
            stepNumber={activeStep}
          />
        </ModalProvider>
      )
      break
    case 4:
      stepContent = (
        <ModalProvider>
          <GenerarRemitosPage
            goToNextStep={goToNextStep}
            stepNumber={activeStep}
          />
        </ModalProvider>
      )
      break
  }
  return (
    <RecepcionPageWrapper>
      <ImageOverlay src={circleImg} alt="Logo" />
      {user.rol.id_rol !== '3' && (
        <StepsWrapper>
          {steps.map((step, index) => (
            <StepCard
              key={index}
              cardData={step}
              index={index}
              activeStep={activeStep}
            />
          ))}
        </StepsWrapper>
      )}
      {pedidoLoading || pedidosLoading ? (
        <RotatingLoading size="large" />
      ) : (
        <TableWrapper>{stepContent}</TableWrapper>
      )}
    </RecepcionPageWrapper>
  )
}

export default RecepcionPage
