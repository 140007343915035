import React from 'react'
import Modal from './Modal'
import { useModal } from 'contexts/ModalContext'
import { usePedidos } from 'contexts/PedidosContext'
import { showToast } from 'components/Notification/ToastNotification'

const DeletePedidoModal = ({
  idProveedor,
  idProducto,
  afterClose,
  redirectAfterDelete = () => {},
}) => {
  const { closeModal } = useModal()

  const { removerMisPedidosCarrito } = usePedidos()
  const content = {
    type: 'error',
    icon: 'trash',
    title: '¿Estás seguro de eliminar?',
    text: ['Si seleccionas ', 'CONFIRMAR ', 'eliminarás el Pedido '],
    primaryButton: {
      function: async () => {
        removerMisPedidosCarrito(idProveedor, idProducto)
        showToast(`Pedido eliminado!`, 'validacion')
        closeModal()
        afterClose()
        redirectAfterDelete()
      },
      text: 'Confirmar',
    },
    secondaryButton: {
      function: () => {
        closeModal()
        afterClose()
      },
      text: 'Cancelar',
    },
    afterClose,
  }
  return <Modal content={content} />
}

export default DeletePedidoModal
