import React, { useEffect } from 'react'

import { useAuth } from 'contexts/AuthContext'
import { ArticulosProvider, useArticulos } from 'contexts/ArticulosContext'
import HomeCoordinador from './HomeCoordinador'
import HomeProveedor from './HomeProveedor'
import HomeAsociadaIndividual from './HomeAsociadaIndividual'
import HomeAsociada from './HomeAsociada'
import { useProveedores } from 'contexts/ProveedoresContext'
import { useCronograma } from 'contexts/CronogramaContext'

const Home = () => {
  const { user } = useAuth()
  const { getPromociones, promociones, promocionesLoading } = useArticulos()
  const { proveedoresActivos, proveedoresDehabilitados, proveedoresLoading } =
    useProveedores()

  const { cronograma } = useCronograma()
  useEffect(() => {
    if (cronograma.id_cronograma) getPromociones(cronograma.id_cronograma)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cronograma])

  const formatData = (data, type) => {
    return type === 'promociones'
      ? data.map((promo) => ({
          title: promo.descripcion,
          proveedor: promo.nombre_comercial,
          topTag: 'Promo',
          botTags: [],
          location: '',
          id: promo.id_articulo,
          path_thumb: promo?.path_thumb,
          precio: promo?.precio,
          zona: promo?.zona,
        }))
      : type === 'proveedores'
        ? data.map((proveedor) => ({
            title: proveedor.nombre_comercial,
            proveedor: proveedor.nombre_comercial,
            topTag: '',
            botTags: [],
            id: proveedor.id,
            habilitado: proveedor.habilitado,
            puedePedir: proveedor.puedePedir,
          }))
        : type === 'alertSection'
          ? data.map((proveedor) => ({
              id: proveedor.id,
              nombre_comercial: proveedor.nombre_comercial,
              zonas: [],
              habilitado: proveedor.habilitado,
              puedePedir: proveedor.puedePedir,
              path_thumb: proveedor.path,
            }))
          : []
  }
  const RolView = {
    Coordinadora: (
      <ArticulosProvider>
        <HomeCoordinador
          formatData={formatData}
          promociones={promociones}
          proveedoresActivos={proveedoresActivos.proveedores}
          proveedoresDehabilitados={proveedoresDehabilitados.proveedores}
          promocionesLoading={promocionesLoading}
          proveedoresLoading={proveedoresLoading}
          cronogramaHabilitado={cronograma.habilitado}
        />
      </ArticulosProvider>
    ),
    Proveedor: <HomeProveedor />,
    Asociada: (
      <ArticulosProvider>
        <HomeAsociada
          formatData={formatData}
          promociones={promociones}
          proveedoresActivos={proveedoresActivos.proveedores}
          promocionesLoading={promocionesLoading}
          proveedoresLoading={proveedoresLoading}
          cronogramaHabilitado={cronograma.habilitado}
        />
      </ArticulosProvider>
    ),
    'Asociada Individual': (
      <ArticulosProvider>
        <HomeAsociadaIndividual
          formatData={formatData}
          promociones={promociones}
          proveedoresActivos={proveedoresActivos.proveedores}
          proveedoresDehabilitados={proveedoresDehabilitados.proveedores}
          promocionesLoading={promocionesLoading}
          proveedoresLoading={proveedoresLoading}
          cronogramaHabilitado={cronograma.habilitado}
        />
      </ArticulosProvider>
    ),
  }

  return RolView[user?.rol?.nombre] || null
}

export default Home
