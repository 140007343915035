import React from 'react'
import styled from 'styled-components'
import Tag from 'components/Tag/Tag'
import Button from 'components/Button/Button'
import { P1, P2 } from 'components/Typography'
import { useNavigate } from 'react-router-dom'
import {
  capitalizeFirstLetter,
  formatPrice,
  replaceText,
  textoCapitalizado,
} from 'utils/textProcess'

const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 192px;
  height: 244px;
  margin: 10px;
  padding: 18px;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  border-radius: 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  color: ${({ theme }) => theme.colors.primary.blue};
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:first-child {
    margin-left: 0;
  }
`

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70%;
  gap: 8px;
`

const TagContainer = styled.div`
  display: inline-flex;
  gap: 6px;
  position: relative;
  margin-bottom: 20px;
`

const CardTitle = styled(P2)`
  height: 40px;
  font-size: 14px;
  margin-top: 18px;
  font-family: 'Roboto-bold';
  line-height: 1;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const CardSubTitle = styled(P1)`
  font-size: 14px;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  font-family: 'Roboto';
`

const CardButton = styled(Button)`
  display: flex;
  align-self: flex-end;
  margin-left: auto;
  min-width: 100px;
  padding: 6px;
`

const FeaturedTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.primary.blue};
  position: absolute;
  top: 20px;
  right: 20px;
  font-family: 'Roboto-bold';
  font-size: 10px;
  text-transform: uppercase;
`

const CardImage = styled.div`
  width: 100%;
  height: 40%;
  background: url(${({ $image }) => $image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

const CardFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto;
`

const CardPrice = styled.div`
  width: 70px;
  text-align: center;
  font-size: 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.blue};
  border: 2px solid ${({ theme }) => theme.colors.primary.blue};
  padding: 6px;
`

const PromoCard = ({ card, cronogramaHabilitado }) => {
  console.log(card)
  // const firstThreeTags = card.botTags ? card.botTags.slice(0, 3) : []
  const navigate = useNavigate()
  return (
    <StyledCard>
      <CardImage $image={card.path_thumb} />
      {card.topTag && <FeaturedTag type="tertiary">{card.topTag}</FeaturedTag>}
      <CardBody>
        <CardTitle>{capitalizeFirstLetter(card.title.toLowerCase())}</CardTitle>
        <CardSubTitle>{textoCapitalizado(card?.proveedor || '')}</CardSubTitle>
        <TagContainer>
          <Tag type={'secondary'}>{card.zona}</Tag>
        </TagContainer>

        <CardFooter>
          <CardPrice>{formatPrice(card.precio)}</CardPrice>
          <CardButton
            type={cronogramaHabilitado ? 'primary' : 'secondary'}
            text={cronogramaHabilitado ? 'Comprar' : 'Ver'}
            size="small"
            onClick={() =>
              navigate(
                `/comprar/${replaceText(card.proveedor, ' ', '-')}/${card.id}`,
              )
            }
          />
        </CardFooter>
      </CardBody>
    </StyledCard>
  )
}

export default PromoCard
