import IconButton from 'components/Button/IconButton'
import ShoppingCart from 'pages/carrito/ShoppingCart/ShoppingCart'
import {
  ShoppingCart as ShoppingCartIcon,
  User,
} from 'feather-icons-react/build/IconComponents'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { ModalProvider } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import CollapsableSearchbar from 'components/CollapsableSearchbar/CollapsableSearchbar'
import { useWindowWidth } from 'contexts/WindowWidthContext'

const StyledNavbarActions = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const ShoppingCartButton = styled(IconButton)`
  position: relative;
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.alarmsStatuses.green};
    border-radius: 50%;
    position: absolute;
    top: -2px;
    right: -2px;
    display: ${({ $carthasproducts }) =>
      $carthasproducts === 'true' ? 'block' : 'none'};
  }
`

const NavbarActions = () => {
  const navigate = useNavigate()
  const [isCartOpen, setIsCartOpen] = useState(false)
  const shoppingCartRef = useRef(null)
  const { width } = useWindowWidth()
  const shoppingCartButtonRef = useRef(null)
  const location = useLocation()
  const pathname = location.pathname.slice(1)
  const { carritoAgrupado } = useCarrito()

  useEffect(() => {
    setIsCartOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function toggleCart() {
    setIsCartOpen((prevIsCartOpen) => !prevIsCartOpen)
  }

  const handleClickOutside = (e) => {
    if (
      shoppingCartButtonRef.current &&
      !shoppingCartButtonRef.current.contains(e.target) &&
      shoppingCartRef.current &&
      !shoppingCartRef.current.contains(e.target)
    ) {
      setIsCartOpen(false)
    }
  }

  useEffect(() => {
    const handleClick = (e) => {
      handleClickOutside(e)
    }

    document.addEventListener('mouseup', handleClick)

    return () => {
      document.removeEventListener('mouseup', handleClick)
    }
  }, [])

  return (
    <StyledNavbarActions>
      {width > 440 && <CollapsableSearchbar />}
      <ShoppingCartButton
        icon={
          <ShoppingCartIcon
            strokeWidth={
              isCartOpen || pathname.toLowerCase().includes('mi-carrito')
                ? 2.5
                : 2
            }
          />
        }
        $message=""
        onClick={toggleCart}
        ref={shoppingCartButtonRef}
        $carthasproducts={
          carritoAgrupado !== undefined &&
          carritoAgrupado?.resumenDetalle &&
          Object.keys(carritoAgrupado?.resumenDetalle).length > 0
            ? 'true'
            : 'false'
        }
      >
        {isCartOpen && (
          <ModalProvider>
            <ShoppingCart
              ref={shoppingCartRef}
              closeCart={() => {
                navigate(`/mi-carrito`)
                setIsCartOpen(false)
              }}
            />
          </ModalProvider>
        )}
      </ShoppingCartButton>
      <IconButton
        icon={
          <User
            strokeWidth={pathname.toLowerCase().includes('mi-perfil') ? 2.5 : 2}
          />
        }
        $message=""
        onClick={() => {
          navigate('/mi-perfil')
        }}
      />
    </StyledNavbarActions>
  )
}

export default NavbarActions
