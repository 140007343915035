import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { ProtectedRoute } from './ProtectedRoute'
import Login from 'pages/login/Login'
import Home from 'pages/home/Home'
import Components from 'components/index'
import Layout from 'pages/Layout'
import CoordinacionPage from 'pages/coordinacion/CoordinacionPage'
import ProductoDetalle from 'pages/comprar/SurtidoProveedores/ProductoDetalle'
import CarritoPage from 'pages/carrito/CarritoPage'
import ComprarPage from 'pages/comprar/HomeComprar/ComprarPage'
import SurtidoProveedoresPage from 'pages/comprar/SurtidoProveedores/SurtidoProveedoresPage'
import DetalleProveedorPage from 'pages/comprar/Proveedores/DetalleProveedorPage'
import PedidosPage from 'pages/pedidos/PedidosPage'
import PedidosAgrupacion from 'pages/pedidos/components/PedidosAgrupacion/PedidosAgrupacionOrdenDeCompra'
import ComprobantesPage from 'pages/coordinacion/ComprobantesPage'
import { ProveedorProvider } from 'contexts/ProveedorContext'
import { PedidosProvider } from 'contexts/PedidosContext'
import RecepcionPage from 'pages/pedidos/RecepcionPage'
import PedidoOrdenDeCompra from 'pages/pedidos/components/MisPedidos/PedidoOrdenDeCompra'
import MiPerfilPage from 'pages/perfil/MiPerfilPage'
import PreguntasFrecuentes from 'pages/home/components/PreguntasFrecuentes'
import TerminosCondiciones from 'pages/home/TerminosCondiciones'
import ResetPassword from 'pages/login/ResetPassword'
import Error404 from 'pages/Error404'
import DetallePedidosConfirmados from 'pages/pedidos/components/MisPedidos/DetallePedidosConfirmados'
import { SearchAndFilterTableProvider } from 'contexts/SearchAndFilterTableContext'
import CosolidacionPage from 'pages/coordinacion/Consolidacion/CosolidacionPage'
import Error from 'pages/Error'
import Tutoriales from 'pages/home/Tutoriales'

const ErrorFallback = () => {
  return <Error />
}
const Routes = () => {
  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: '*',
      element: (
        <Layout dontHandleRoutes={true}>
          <Error404 />
        </Layout>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: '/login',
      element: (
        <Layout>
          <Login />
        </Layout>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: '/preguntas-frecuentes',
      element: (
        <Layout>
          <PreguntasFrecuentes />
        </Layout>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: '/terminos-condiciones',
      element: (
        <Layout>
          <TerminosCondiciones />,
        </Layout>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: '/reset-password',
      element: (
        <Layout>
          <ResetPassword />,
        </Layout>
      ),
      errorElement: <ErrorFallback />,
    },
  ]

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: '/',
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: '',
          element: <Home />,
          errorElement: <ErrorFallback />,
        },
        {
          path: '/tutoriales',
          element: <Tutoriales />,

          errorElement: <ErrorFallback />,
        },
        {
          path: '/coordinacion',
          element: (
            <PedidosProvider>
              <CoordinacionPage />
            </PedidosProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/consolidacion-pedido/:nombreProveedor',
          element: (
            <ProveedorProvider>
              <PedidosProvider>
                <CosolidacionPage />
              </PedidosProvider>
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/comprar',
          element: <ComprarPage />,
          errorElement: <ErrorFallback />,
        },
        {
          path: '/pedidos',
          element: (
            <PedidosProvider>
              <SearchAndFilterTableProvider>
                <PedidosPage />
              </SearchAndFilterTableProvider>
            </PedidosProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/pedidos/recepcion/:idPedido',
          element: (
            <PedidosProvider>
              <RecepcionPage />,
            </PedidosProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/comprar/:nombreProveedor/:idArticulo',
          element: (
            <ProveedorProvider>
              <ProductoDetalle />
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          //TODO mejorar el Breadcrumbs de esta ruta
          path: '/comprar/:nombreProveedor/id/:idProveedor',
          element: (
            <ProveedorProvider>
              <SurtidoProveedoresPage />
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/mis-pedidos/:ordenCompra',
          element: (
            <PedidosProvider>
              <PedidoOrdenDeCompra />
            </PedidosProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/pedidos-agrupacion/:ordenCompra',
          element: (
            <PedidosProvider>
              <PedidosAgrupacion />
            </PedidosProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/detalle-proveedor/:nombreProveedor',
          element: (
            <ProveedorProvider>
              <DetalleProveedorPage />
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/mi-carrito',
          element: (
            <ProveedorProvider>
              <CarritoPage />
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/mi-perfil',
          element: <MiPerfilPage />,
          errorElement: <ErrorFallback />,
        },
        {
          path: '/:pedidos/comprobantes/:idComprobante',
          element: (
            <ProveedorProvider>
              <PedidosProvider>
                <ComprobantesPage />
              </PedidosProvider>
            </ProveedorProvider>
          ),
          errorElement: <ErrorFallback />,
        },
        {
          path: '/mis-pedidos/confirmados/:idCarrito',
          element: (
            <ProveedorProvider>
              <PedidosProvider>
                <DetallePedidosConfirmados />
              </PedidosProvider>
            </ProveedorProvider>
          ),
        },
      ],
    },
  ]

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    process.env.NODE_ENV === 'development' && {
      path: '/componentesExamples',
      element: <Components />,
    },
  ])

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />
}

export default Routes
