import React from 'react'
import styled from 'styled-components'

import NotificationCenter from '../../components/Notification/NotificationCenter'
import CardCarousel from './components/CardCarousel'
import { H4 } from 'components/Typography'
import { Wrapper } from 'components/layout/FlexComponents'
import { useCronograma } from 'contexts/CronogramaContext'

const CronogramaText = styled(H4)`
  align-self: flex-start;
  margin: 20px 180px;
  z-index: 2;

  @media (max-width: 440px) {
    margin: 10px 40px;
  }
`

const NotificationHome = styled(NotificationCenter)`
  padding: 0 10%;
  margin-top: 16px;
`

const HomeProveedor = () => {
  const { cronograma } = useCronograma()

  return (
    <Wrapper>
      <NotificationHome />
      <Wrapper>
        <CronogramaText>{`Cronograma N°${cronograma.id_cronograma}`}</CronogramaText>
        <CardCarousel />
      </Wrapper>
    </Wrapper>
  )
}

export default HomeProveedor
