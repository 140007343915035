import React, { useRef, useState } from 'react'
import styled from 'styled-components'

import { H2, H5 } from 'components/Typography'
import ProveedoresCompraTable from './components/ProveedoresCompraTable'
import Link from 'components/Button/Link'
import { ShoppingCart } from 'feather-icons-react/build/IconComponents'
import Pagination from 'components/Table/Pagination'
import NumberElement from 'components/Table/NumberElement'
import FilterSection from './components/FilterSectionComprar'
import { useProveedores } from 'contexts/ProveedoresContext'
import { useEffect } from 'react'
import Loading from 'components/Loading/Loading'
import { ModalProvider } from 'contexts/ModalContext'
import { useCarrito } from 'contexts/CarritoContext'
import HorizontalPromoCarousel from 'components/Carousel/HorizontalPromoCarousel'
import { shuffle } from 'utils/arrayFunctions'
import { useArticulos } from 'contexts/ArticulosContext'
import axios from 'axios'
import { useFilters } from 'contexts/FiltersContext'
import { useCronograma } from 'contexts/CronogramaContext'
import { useWindowWidth } from 'contexts/WindowWidthContext'
import { getLocalStorageItem } from 'utils/loadData'
import MobileFilterComponent from 'components/FilterComponent/MobileFilterComponent'

const WrapperComprarPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  padding: 20px 40px;

  @media (max-width: 440px) {
    padding: 20px;
    flex-direction: column;
    gap: 18px;
  }
`

const TableSection = styled.div`
  width: 75%;

  @media (max-width: 440px) {
    width: 100%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
  margin-bottom: 10px;
`

const FooterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const ShoppingCartIcon = styled(ShoppingCart)`
  margin-right: 10px;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.darkBlue};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`

const StyledHorizontalPromoCarousel = styled(HorizontalPromoCarousel)``

const formatData = (data) => {
  return data.map((promo) => ({
    title: promo.descripcion,
    proveedor: promo.proveedor,
    topTag: 'Promo',
    botTags: [],
    location: '',
    id: promo.id_articulo,
    precio: promo?.precio,
    zona: promo?.zona,
  }))
}

const ComprarPage = () => {
  const { proveedores, getProveedores, proveedoresLoading, proveedoresPager } =
    useProveedores()
  const [currentPage, setCurrentPage] = useState(
    getLocalStorageItem('comprarPageNumber') || 1,
  )

  const [searchValue, setSearchValue] = useState('')
  const { comprarFilters, onComprarFiltersChange, setComprarFilters } =
    useFilters()
  const { loadingCarrito } = useCarrito()
  const { width } = useWindowWidth()
  const { promociones, promocionesLoading } = useArticulos()
  const { cronograma } = useCronograma()
  const prevSearchValueRef = useRef(searchValue)
  const prevFiltersRef = useRef(comprarFilters)

  useEffect(() => {
    const source = axios.CancelToken.source()

    const fetchPedidosAgrupacion = async () => {
      try {
        const queryData = { paginaActual: currentPage }
        const { operación_directa, marcas_líderes, cronograma_actual } =
          comprarFilters

        if (searchValue !== '') queryData.buscar = searchValue
        if (operación_directa || marcas_líderes) {
          queryData.tipo_proveedor = operación_directa ? 0 : 1
        }
        if (cronograma_actual) {
          queryData.habilitados = 1
        } else {
          delete queryData.habilitados
        }

        await getProveedores({ ...queryData, cancelToken: source.token })

        // Actualiza las referencias después de la ejecución
        prevSearchValueRef.current = searchValue
        prevFiltersRef.current = comprarFilters
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Solicitud previa cancelada:', error.message)
        } else {
          console.error('Error al obtener los pedidos de agrupación:', error)
        }
      }
    }

    const filtersChanged =
      JSON.stringify(prevFiltersRef.current) !== JSON.stringify(comprarFilters)
    const searchChanged = prevSearchValueRef.current !== searchValue

    if (filtersChanged || searchChanged) {
      localStorage.setItem('comprarPageNumber', JSON.stringify(1))
      setCurrentPage(1)
    }

    fetchPedidosAgrupacion()

    return () => {
      source.cancel('Operación cancelada debido a una nueva solicitud.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchValue, comprarFilters])

  const handlePageChange = (pageNumber) => {
    localStorage.setItem('comprarPageNumber', JSON.stringify(pageNumber))
    setCurrentPage(pageNumber)
  }

  const calcularCurrentElement = (cantidadItemsTabla) => {
    return proveedoresPager.pagina_actual === 1
      ? cantidadItemsTabla
      : parseInt(cantidadItemsTabla) -
          10 +
          parseInt(proveedoresPager.por_pagina) *
            parseInt(proveedoresPager.pagina_actual)
  }

  return (
    proveedores && (
      <WrapperComprarPage>
        <MobileFilterComponent>
          <FilterSection
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filters={comprarFilters}
            setFilters={onComprarFiltersChange}
          />
        </MobileFilterComponent>
        {proveedoresLoading || loadingCarrito ? (
          <Loading />
        ) : (
          <TableSection>
            <>
              <Header>
                <Title>
                  <H2>Comprar</H2>
                  <H5>
                    {proveedoresPager.total
                      ? proveedoresPager.total + ' '
                      : '0 '}
                    resultados
                  </H5>
                </Title>
              </Header>

              <ModalProvider>
                <ProveedoresCompraTable
                  data={proveedores}
                  width={width}
                  resetFilter={() => {
                    setSearchValue('')
                    setComprarFilters({
                      cronograma_actual: false,
                      operación_directa: false,
                      marcas_líderes: false,
                      todos_provedores: true,
                      directa_y_redistribucion: true,
                    })
                  }}
                />
              </ModalProvider>
              <TableWrapper>
                <NumberElement
                  currentElement={calcularCurrentElement(proveedores?.length)}
                  totalElements={proveedoresPager.total}
                />
                <Pagination
                  totalPages={proveedoresPager.ultima_pagina}
                  currentPage={proveedoresPager.pagina_actual}
                  onPageChange={handlePageChange}
                />
              </TableWrapper>

              <FooterSection>
                <StyledLink href="/mi-carrito">
                  <ShoppingCartIcon /> Ver mi carrito
                </StyledLink>
              </FooterSection>
            </>
          </TableSection>
        )}
        {!promocionesLoading &&
          promociones &&
          promociones.promociones &&
          Object.keys(promociones.promociones).length > 0 && (
            <StyledHorizontalPromoCarousel
              title="Promociones"
              type="primary"
              cards={formatData(
                shuffle(promociones.promociones),
                'promociones',
              )}
              cronogramaHabilitado={cronograma.habilitado}
            />
          )}
      </WrapperComprarPage>
    )
  )
}

export default ComprarPage
