import React, { createContext, useContext, useEffect, useState } from 'react'
import CarritoService from 'services/carrito'
import { useCronograma } from 'contexts/CronogramaContext'
import { useAuth } from './AuthContext'
import { getLocalStorageItem } from 'utils/loadData'
const CarritoContext = createContext()

export const useCarrito = () => useContext(CarritoContext)

export const CarritoProvider = ({ children }) => {
  const { token, user } = useAuth()
  const { cronograma } = useCronograma()
  const [carritoAgrupado, setCarritoAgrupado] = useState(() =>
    getLocalStorageItem('carritoAgrupadoData', {}),
  )
  const [carrito, setCarrito] = useState(() =>
    getLocalStorageItem('carritoData', {}),
  )

  const [isFinalizado, setIsFinalizado] = useState(false)
  const [loadingAddCarrito, setLoadingAddCarrito] = useState(false)
  const [loadingCarrito, setLoadingCarrito] = useState(false)
  const [loadingCarritoAgrupado, setLoadingCarritoAgrupado] = useState(false)
  const [loadingCarritoFinalizar, setLoadingCarritoFinalizar] = useState(false)

  const fetchData = async (id) => {
    if (isFinalizado) {
      setLoadingCarrito(true)
    }
    try {
      if (token && cronograma?.id_cronograma && carritoAgrupado?.cabecera?.id) {
        const resp = await CarritoService.getCarrito({
          token,
          idCronograma: cronograma.id_cronograma,
          idCarrito: id ? id : carritoAgrupado.cabecera.id,
        })
        setCarrito(resp.data.data)
        localStorage.setItem('carritoData', JSON.stringify(resp.data.data))
      }
    } catch (error) {
      console.error('Error al obtener los datos del carrito:', error)
      throw error
    } finally {
      setIsFinalizado(false)
      setLoadingCarrito(false)
    }
  }

  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carritoAgrupado])

  const updateLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data))
  }

  const getCarritoAgrupado = async () => {
    setLoadingCarritoAgrupado(true)
    try {
      const resp = await CarritoService.getCarritoAgrupado({
        token,
        idCronograma: cronograma.id_cronograma,
      })
      updateLocalStorage('carritoAgrupadoData', resp)
      setCarritoAgrupado(resp)
      return resp
    } catch (error) {
      console.error('Error al crear carrito:', error)
      throw error
    } finally {
      setLoadingCarritoAgrupado(false)
    }
  }

  const finalizar = async ({
    idProveedor,
    ocGrupo,
    fechaVto,
    observacion,
    idZona,
    idLugarEntrega,
    tieneReqLog,
    superoReqLog,
  }) => {
    if (token) {
      const idCarrito = carritoAgrupado.cabecera.id
      setLoadingCarritoFinalizar(true)
      setIsFinalizado(true)
      try {
        const resp = await CarritoService.finalizar({
          idCronograma: cronograma.id_cronograma,
          idCarrito,
          idProveedor,
          token,
          rol: user.rol.id_rol,
          ocGrupo,
          fechaVto,
          observacion,
          idZona,
          idLugarEntrega,
          tieneReqLog,
          superoReqLog,
        })
        console.log(resp)
        // setCarritoAgrupado(resp.data.data)
        // updateLocalStorage('carritoAgrupadoData', resp.data.data)
        // fetchData(resp.data.data.cabecera.id)
        await getCarritoAgrupado()
      } catch (error) {
        console.error('Error al finalizar el carrito:', error)
        throw error
      } finally {
        setLoadingCarritoFinalizar(false)
      }
    }
  }

  const agregarItem = async (idArticulo, cantidad) => {
    if (token) {
      setLoadingAddCarrito(true)
      try {
        const resp = await CarritoService.agregarItem({
          idCarrito: carritoAgrupado.cabecera.id,
          idArticulo,
          cantidad,
          token,
        })

        console.log(resp)
        getCarritoAgrupado()
      } catch (error) {
        console.error('Error al actualizar el carrito:', error)
        throw error
      } finally {
        setLoadingAddCarrito(false)
      }
    }
  }

  const remover = async (idProveedor) => {
    if (token) {
      const idCarrito = carritoAgrupado.cabecera.id
      try {
        await CarritoService.remover({
          idCarrito,
          idProveedor,
          token,
        })
        getCarritoAgrupado()
      } catch (error) {
        console.error('Error al remover proveedor de carrito:', error)
        throw error
      }
    }
  }

  const comprarEnOtraOcasion = async (idProveedor) => {
    if (token) {
      setLoadingCarrito(true)
      try {
        const resp = await CarritoService.comprarEnOtraOcasion({
          idCarrito: carritoAgrupado.cabecera.id,
          idProveedor,
          idCronograma: cronograma.id_cronograma,
          token,
        })
        setCarritoAgrupado(resp.data.data)
        updateLocalStorage('carritoAgrupadoData', resp.data.data)
      } catch (error) {
        console.error('Error al actualizar el carrito:', error)
        throw error
      } finally {
        setLoadingCarrito(false)
      }
    }
  }

  const cancelarComprarEnOtraOcasion = async (idProveedor) => {
    if (token) {
      setLoadingCarrito(true)
      try {
        await CarritoService.cancelarComprarEnOtraOcasion({
          idProveedor,
          idCronograma: cronograma.id_cronograma,
          token,
        })

        const comprarFilters = getLocalStorageItem('comprarFilters', {
          cronograma_actual: false,
          operación_directa: false,
          marcas_líderes: false,
          todos_provedores: true,
          directa_y_redistribucion: true,
        })

        const { operación_directa, marcas_líderes, cronograma_actual } =
          comprarFilters
        const queryData = {}

        if (operación_directa || marcas_líderes) {
          queryData.tipo_proveedor = operación_directa ? 0 : 1
        }
        if (cronograma_actual) {
          queryData.habilitados = 1
        } else {
          delete queryData.habilitados
        }
      } catch (error) {
        console.error('Error al actualizar el carrito:', error)
        throw error
      } finally {
        setLoadingCarrito(false)
      }
    }
  }

  const noComprarEstaSemana = async () => {
    if (token) {
      setLoadingCarrito(true)
      try {
        const resp = await CarritoService.noComprarEstaSemana({
          idCarrito: carritoAgrupado.cabecera.id,
          idCronograma: cronograma.id_cronograma,
          token,
        })

        setCarritoAgrupado(resp.data.data)
        updateLocalStorage('carritoAgrupadoData', resp.data.data)
      } catch (error) {
        console.error('Error al actualizar el carrito:', error)
        throw error
      } finally {
        setLoadingCarrito(false)
      }
    }
  }

  const contextValues = {
    carritoAgrupado,
    setCarritoAgrupado,
    getCarritoAgrupado,
    finalizar,
    agregarItem,
    remover,
    comprarEnOtraOcasion,
    noComprarEstaSemana,
    cancelarComprarEnOtraOcasion,
    carrito,
    loadingAddCarrito,
    loadingCarrito,
    setLoadingCarrito,
    loadingCarritoFinalizar,
    loadingCarritoAgrupado,
    setLoadingCarritoAgrupado,
  }

  return (
    <CarritoContext.Provider value={contextValues}>
      {children}
    </CarritoContext.Provider>
  )
}
