import useAxiosInstance from './axiosInterceptor'
const axios = useAxiosInstance
import {
  getNumbersFromString,
  queryString,
  reemplazarInterrogacionPorÑ,
} from 'utils/textProcess'

const PedidosService = {
  getParticipacion: async ({ idCronograma, idProveedor, idGrupo, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_grupo: parseInt(idGrupo),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedor-participacion?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getMatrizConsolidacion: async ({
    idCronograma,
    idProveedor,
    idZona,
    token,
  }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_zona: parseInt(idZona),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/proveedor-matriz-consolidacion?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getOc: async ({ idOcCabecera, token }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedido?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedido.proveedor = reemplazarInterrogacionPorÑ(
          response.data.data.pedido.proveedor,
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getOcAgrupacion: async ({ idOcCabecera, token }) => {
    let data = {
      id_oc_cabecera: parseInt(getNumbersFromString(idOcCabecera)),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedido-agrupacion?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedido.proveedor = reemplazarInterrogacionPorÑ(
          response.data.data.pedido.proveedor,
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getMisPedidos: async ({
    idCronograma,
    paginaActual,
    porPagina,
    activeOrder,
    fechas,
    tipo_proveedor,
    token,
    estado,
    searchValue,
    cancelToken,
  }) => {
    let data = {
      pagina_actual: paginaActual,
      por_pagina: porPagina,
    }
    if (idCronograma) {
      data.id_cronograma = parseInt(idCronograma)
    }
    if (fechas) {
      if (fechas.fecha_desde) {
        data.fecha_desde = fechas.fecha_desde
      }
      if (fechas.fecha_hasta) {
        data.fecha_hasta = fechas.fecha_hasta
      }
    }
    if (tipo_proveedor !== null) {
      data.tipo_proveedor = tipo_proveedor
    }
    if (activeOrder) {
      data.orden = activeOrder
    }
    if (searchValue) {
      data.buscar = searchValue
    }

    if (estado !== null) {
      // if (estado === 5) {
      //   data.estado = estado
      // } else if (recepcion !== 0) {
      //   data.recepcion = recepcion
      // } else {
      //   data.recepcion = recepcion
      data.estado = estado
      // }
    }
    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedidos?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
      cancelToken,
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedidos.map(
          (p) => (p.proveedor = reemplazarInterrogacionPorÑ(p.proveedor)),
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getPedidosAgrupacion: async ({
    idCronograma,
    paginaActual,
    porPagina,
    activeOrder,
    fechas,
    tipo_proveedor,
    token,
    estado,
    searchValue,
    // recepcion,
    cancelToken,
  }) => {
    let data = {
      pagina_actual: paginaActual,
      por_pagina: porPagina,
    }
    if (idCronograma) {
      data.id_cronograma = parseInt(idCronograma)
    }
    if (fechas) {
      if (fechas.fecha_desde) {
        data.fecha_desde = fechas.fecha_desde
      }
      if (fechas.fecha_hasta) {
        data.fecha_hasta = fechas.fecha_hasta
      }
    }
    if (tipo_proveedor !== null) {
      data.tipo_proveedor = tipo_proveedor
    }
    if (activeOrder) {
      data.orden = activeOrder
    }
    if (searchValue) {
      data.buscar = searchValue
    }
    if (estado !== null) {
      // if (estado === 5) {
      //   data.estado = estado
      // } else if (recepcion !== 0) {
      //   data.recepcion = recepcion
      // } else {
      //   data.recepcion = recepcion
      data.estado = estado
    }
    // }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedidos-agrupacion?${queryString(
        data,
      )}`,
      headers: { Authorization: `Bearer ${token}` },
      cancelToken,
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedidos.map(
          (p) => (p.proveedor = reemplazarInterrogacionPorÑ(p.proveedor)),
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getPedidoConfeccionado: async ({ idCarrito, idProveedor, token }) => {
    let data = {
      id_carrito: parseInt(idCarrito),
      id_proveedor: parseInt(idProveedor),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedido-confeccionado?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.cabecera.condiciones.nombre_comercial =
          reemplazarInterrogacionPorÑ(
            response.data.data.cabecera.condiciones.nombre_comercial,
          )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  // agregar o editar este get dependiendo de datos de postman (ver que valores necesita)
  getPedidoAgrupacion: async ({
    idOcCabecera,
    // paginaActual,
    // porPagina,
    token,
  }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
      recepcion: true,
      // pagina_actual: paginaActual,
      // por_pagina: porPagina,
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedido-agrupacion?${queryString(
        data,
      )}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedido.proveedor = reemplazarInterrogacionPorÑ(
          response.data.data.pedido.proveedor,
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getPedidosAConsolidar: async ({ idCronograma, idGrupo, token }) => {
    let data = {
      id_cronograma: parseInt(idCronograma),
      id_grupo: parseInt(idGrupo),
    }

    let config = {
      method: 'GET',
      url: `${
        process.env.REACT_APP_API_URL
      }/proveedores-a-consolidar?${queryString(data)}`,
      headers: { Authorization: `Bearer ${token}` },
    }

    return axios
      .request(config)
      .then((response) => {
        response.data.data.proveedores.map(
          (p) =>
            (p.nombre_comercial = reemplazarInterrogacionPorÑ(
              p.nombre_comercial,
            )),
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  getComprobantes: async ({ idOcCabecera, idAfiliado, token }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
    }
    if (idAfiliado) {
      data['id_afiliado'] = idAfiliado
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pedidos-comprobantes?${queryString(
        data,
      )}`,
      headers: { Authorization: `Bearer ${token}` },
    }
    return axios
      .request(config)
      .then((response) => {
        response.data.data.pedido.razon_social = reemplazarInterrogacionPorÑ(
          response.data.data.pedido.razon_social,
        )
        return response
      })
      .catch((error) => {
        throw error
      })
  },
  consolidarPedido: async ({
    idCronograma,
    idProveedor,
    idGrupo,
    oc_grupo,
    fecha_vto,
    id_zona,
    id_lugar,
    observacion,
    token,
  }) => {
    let data = JSON.stringify({
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_grupo: parseInt(idGrupo),
      oc_grupo: parseInt(oc_grupo),
      fecha_vto,
      observacion,
      id_zona: parseInt(id_zona),
      id_entrega: 0,
      id_lugar_entrega: parseInt(id_lugar),
      tiene_req_log: true,
      supero_req_log: true,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/proveedor-consolidar`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  cancelarConfirmacionCarrito: async ({
    idCronograma,
    idProveedor,
    idCarrito,
    token,
  }) => {
    let data = JSON.stringify({
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_carrito: parseInt(idCarrito),
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/carrito-cancelar`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  consolidarPedidoMatriz: async ({
    idCronograma,
    idProveedor,
    idGrupo,
    oc_grupo,
    fecha_vto,
    id_zona,
    id_lugar,
    observacion,
    afiliados,
    token,
  }) => {
    let data = JSON.stringify({
      id_cronograma: parseInt(idCronograma),
      id_proveedor: parseInt(idProveedor),
      id_grupo: parseInt(idGrupo),
      oc_grupo: parseInt(oc_grupo),
      fecha_vto,
      observacion,
      id_zona: parseInt(id_zona),
      id_entrega: 0,
      id_lugar_entrega: parseInt(id_lugar),
      tiene_req_log: true,
      supero_req_log: true,
      observacion_grupo: true,
      es_extra: false,
      afiliados,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/proveedor-matriz-consolidacion`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  recibirCantidadesPedido: async ({ idOcCabecera, cantidades, token }) => {
    let data = JSON.stringify({
      id_oc_cabecera: parseInt(idOcCabecera),
      cantidades,
      token,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/recepcion-cantidades`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  distribuirPedido: async ({
    idOcCabecera,
    idCabeceraRecepcion,
    cantidades,
    token,
  }) => {
    let data = JSON.stringify({
      id_cabecera_recepcion: idCabeceraRecepcion,
      id_oc_cabecera: idOcCabecera,
      afiliados: cantidades,
      token,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/recepcion-distribucion`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  generarRemitos: async ({
    idOcCabecera,
    idCabeceraRecepcion,
    remitos,
    token,
  }) => {
    let data = JSON.stringify({
      id_oc_cabecera: parseInt(idCabeceraRecepcion),
      id_cabecera_recepcion: parseInt(idOcCabecera),
      remitos,
      remito_padre: '',
      token,
    })

    let config = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/recepcion-remitos`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data.data
      })
      .catch((error) => {
        throw error
      })
  },
  obtenerPdfPedidoSubasociada: async ({
    idOcCabecera,
    idSubasociada,
    token,
  }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
      id_afiliado: parseInt(idSubasociada),
    }
    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pdf-pedido-subasociada?${queryString(data)}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        throw error
      })
  },
  obtenerPdfPedidoAgrupacion: async ({ idOcCabecera, token }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pdf-pedido-agrupacion?${queryString(data)}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        throw error
      })
  },
  obtenerPdfRemito: async ({
    idOcCabecera,
    idCabeceraRecepcion,
    idAfiliado,
    token,
  }) => {
    let data = {
      id_oc_cabecera: parseInt(idOcCabecera),
      id_cabecera_recepcion: parseInt(idCabeceraRecepcion),
      token,
    }

    if (idAfiliado) {
      data['id_afiliado'] = idAfiliado
    }

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pdf-remito?${queryString(data)}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        throw error
      })
  },
  obtenerPdfFactura: async ({
    idFactura,
    // idOcCabecera,
    // idCabeceraRecepcion,
    // idAfiliado,
    token,
  }) => {
    let data = {
      id_factura: parseInt(idFactura),
      // id_oc_cabecera: parseInt(idOcCabecera),
      // id_cabecera_recepcion: parseInt(idCabeceraRecepcion),
      token,
    }

    /* if (idAfiliado) {
      data['id_afiliado'] = idAfiliado
    } */

    let config = {
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}/pdf-factura?${queryString(data)}`,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    }

    return axios
      .request(config)
      .then(({ data }) => {
        return data
      })
      .catch((error) => {
        throw error
      })
  },
}

export default PedidosService
